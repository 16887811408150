import { useEffect } from "react"

const ScrollToTop = (container: Element | null) => {
  useEffect(() => {
    const scrollToTop = () => {
      if (container) {
        container.scrollTop = 0
      }
    }

    scrollToTop()
  }, [container])
}

export default ScrollToTop
