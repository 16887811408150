import UserDropdown from "./user-dropdown"
import MobileSidebar from "./mobile-sidebar"
import LetstokLogo from "@components/customized/logo"

const Navbar = () => {
  return (
    <nav
      className="z-10 flex
     h-[var(--nav-height)] items-center 
     justify-between border-b 
     border-[var(--color-gray)] px-3 py-3
     lg:px-8"
    >
      <MobileSidebar />
      <LetstokLogo />
      <UserDropdown />
    </nav>
  )
}
export default Navbar
