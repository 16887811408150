import { useEffect, useState } from "react"
import ordersService from "services/api/REST/orders"
import { UserOrder } from "services/api/REST/orders/orders-api.types"

function useFetchDoneOrders() {
  const [doneOrders, setDoneOrders] = useState<UserOrder[]>([])
  const fetchDoneOrders = async () => {
    const doneOrders = await ordersService.getOrders(
      { limit: 999, page: 1 },
      "done"
    )

    if (!doneOrders || !doneOrders?.data) return

    setDoneOrders(doneOrders.data)
  }

  useEffect(() => {
    fetchDoneOrders()
  }, [])

  return [doneOrders]
}

export default useFetchDoneOrders
