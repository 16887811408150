import Env from "@utils/env"
import networkService from "services/network/network.service"
import { IGetStickyTitleText, StickyTitleText } from "./media-api.interfaces"

async function getStickyTitleText(
  params: IGetStickyTitleText
): Promise<StickyTitleText[]> {
  const searchParams = new URLSearchParams()
  searchParams.append("categoryId", params.categoryId.toString())
  searchParams.append("verticalId", params.verticalId.toString())

  const url = `${Env.get("REACT_APP_BASE_URL")}/sticky-tittle-text?${searchParams.toString()}`
  const stickyTitleTextList: StickyTitleText[] = await networkService
    .fetch(url, {
      method: "GET",
    })
    .catch((error) => {
      throw new Error(error)
    })

  if (!stickyTitleTextList.length)
    throw new Error("No stickyTitleText data received.")

  return stickyTitleTextList
}

export default getStickyTitleText
