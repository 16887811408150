import { Box } from "@components/customized"
import { DatePicker as AntPicker } from "antd"
import { DatePicker as SimplePicker} from "antd"
import { ComponentProps, useMemo } from "react"
import "./date-picker.css"
import InputLabel from "../input-label"
import dayjs from 'dayjs';

type RangePickerProps = ComponentProps<typeof AntPicker.RangePicker>
type MonthPickerProps = ComponentProps<typeof AntPicker.MonthPicker>

type Props = {
  type?: "date" | "month" | "range"
  rangePickerProps?: RangePickerProps
  monthPickerProps?: MonthPickerProps
  title?: string
  columnView?: boolean
  value?: Date,
  disabled?: boolean,
  format?: string,
}
const DatePicker: React.FC<Props> = (props) => {
  const { type, title, columnView = true, rangePickerProps, monthPickerProps, value } = props
  const Picker = useMemo(() => {
    if (type === "range") return <AntPicker.RangePicker {...rangePickerProps} />
    if (type === "month") return <AntPicker.MonthPicker {...monthPickerProps} />
    // return <AntPicker.RangePicker {...rangePickerProps} />
    return <SimplePicker disabled {...props} value={value ? dayjs(value) : null} />
  }, [rangePickerProps, type, value ])

  return (
    <Box className="flex flex-col">
      <InputLabel columnView={columnView} label={title}>
        {Picker}
      </InputLabel>
    </Box>
  )
}

export default DatePicker
