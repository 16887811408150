import { observer } from "mobx-react-lite"
import { appRouter } from "@router/router"
import { RouterProvider } from "react-router-dom"
import { ConfigProvider } from "antd"
import { themeConfiguration } from "@utils/styles/theme"
import { IntercomProvider } from "providers"
function App() {
  return (
    <IntercomProvider>
      <ConfigProvider theme={themeConfiguration}>
        <RouterProvider router={appRouter} />
      </ConfigProvider>
    </IntercomProvider>
  )
}

export default observer(App)
