import { useState } from "react"
import { Switch as AntSwitch, SwitchProps } from "antd"
import { Box } from "@components/customized"
import InputLabel, { InputLabelProps } from "../input-label"

type Props = SwitchProps &
  InputLabelProps & {
    children?: React.ReactNode
  }
const Switch: React.FC<Props> = (props) => {
  const { title, children, columnView, tooltip, ...rest } = props
  const [checked, setChecked] = useState<boolean>(false)

  const handleOnCheck = (isCheck: boolean) => {
    setChecked(isCheck)
  }

  return (
    <Box className="flex h-[64px] w-full flex-col items-center">
      <InputLabel tooltip={tooltip} columnView={columnView} label={title}>
        <AntSwitch
          {...rest}
          onChange={(isCheck, event) => {
            handleOnCheck(isCheck)
            return rest.onChange ? rest.onChange(isCheck, event) : null
          }}
          className="w-min bg-[var(--color-secondary)]"
        />
      </InputLabel>
      {checked && <Box className="flex flex-row items-center">{children}</Box>}
    </Box>
  )
}

export default Switch
