import { useIntercom } from "react-use-intercom"
import userStore from "@stores/user/user.store"
import { Outlet } from "react-router-dom"
import { Suspense, useEffect } from "react"
import Notification from "../notification/notification.component"
import Loader from "../loader/loader.component"
import Sidebar from "./sidebar"
import Navbar from "./navbar"
import { useLoadCreators, useLoadMusicList } from "@utils/hooks"
import useLoadCategories from "@utils/hooks/useLoadCategories"
import { observer } from "mobx-react-lite"

const Layout = () => {
  const { boot } = useIntercom()
  const { fetchMusicHandler } = useLoadMusicList()
  const { isLoading } = useLoadCategories()
  useLoadCreators()
  useEffect(() => {
    if (userStore.loggedIn) {
      boot({
        name: userStore.user?.fullName,
        email: userStore.user?.email,
        userId: userStore.user?.id,
        actionColor: "#f24462",
        backgroundColor: "#4a4a4a",
      })
      fetchMusicHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Notification />
      <div className="flex h-screen flex-col">
        <Navbar />
        {isLoading ? (
          <Loader />
        ) : (
          <main
            className="grid h-full w-full grid-cols-1 
        overflow-hidden overflow-y-auto
        lg:grid-cols-[200px_1fr]"
            id="main-container"
          >
            <div className="relative hidden h-full lg:block">
              <Sidebar />
            </div>
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </main>
        )}
      </div>
    </>
  )
}

export default observer(Layout)
