import { makeAutoObservable, runInAction } from "mobx"
import { UserPlan } from "services/api/REST/users/plan/plan.types"
import { IUser, SSOAuth } from "services/api/REST/users/users-api.interfaces"
class UserStore {
  public loggedIn: boolean = false
  public user?: IUser
  public plan?: UserPlan

  constructor() {
    makeAutoObservable(this)
  }

  setUserLoggedIn(loggedIn: boolean, sso?: SSOAuth) {
    runInAction(() => {
      if (loggedIn && sso) {
        this.user = sso.user
        this.plan = sso.plan
      }
      if (!loggedIn) {
        this.user = undefined
        this.plan = undefined
      }
      this.loggedIn = loggedIn
    })
  }
}

const userStore = new UserStore()
export default userStore
