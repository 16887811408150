import { TreeSelect } from "@components/controllers"
import { useCategoriesUtil } from "@utils/hooks"
import { observer } from "mobx-react-lite"
import { useCallback } from "react"
import { Category, Vertical } from "services/api/REST/common"

interface Props {
  category?: string | number
  vertical?: string | number

  onVerticalChange?: (vertical?: Vertical) => void
  onCategoryChange?: (category?: Category) => void

  isParentDisabled?: boolean
  clearable?: boolean
}
const CategoriesTreeSelect: React.FC<Props> = (props) => {
  const {
    onVerticalChange,
    onCategoryChange,
    category,
    isParentDisabled,
    vertical,
    clearable,
  } = props
  const { getCategoriesTreeSelectOptions } = useCategoriesUtil()
  const categoriesTreeSelectOptions = getCategoriesTreeSelectOptions({
    isParentDisabled,
  })
  /**
   * Because cannot have two children with same value in tree
   * and we need to get category and vertical from value
   */
  const getCategoryWithVertical = useCallback(
    (value: number) => {
      let category: undefined | Category = undefined
      let vertical: undefined | Vertical = undefined
      categoriesTreeSelectOptions.forEach((ctg) => {
        if (ctg.value === value) {
          category = {
            id: value,
            category: ctg.label,
          }
          vertical = undefined
        }
      })
      if (!category) {
        categoriesTreeSelectOptions.forEach((option) => {
          if (option.children) {
            option.children.forEach((child) => {
              if (child.value === value) {
                category = {
                  id: option.value,
                  category: option.label,
                }
                vertical = {
                  id: child.value,
                  vertical: child.label,
                }
              }
            })
          }
        })
      }
      return {
        category,
        vertical,
      }
    },
    [categoriesTreeSelectOptions]
  )
  return (
    <TreeSelect
      value={vertical || category}
      placeholder="..."
      allowClear={clearable}
      treeData={categoriesTreeSelectOptions}
      title="Category and Vertical"
      treeNodeFilterProp="label"
      onChange={(value, list, extra) => {
        const { category, vertical } = getCategoryWithVertical(value)
        onCategoryChange?.(category)
        onVerticalChange?.(vertical)
      }}
    />
  )
}
export default observer(CategoriesTreeSelect)
