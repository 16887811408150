import { Button } from "@components/controllers"
import useFetchAllUsers from "./hooks/useFetchAllUsers"
import useFetchDoneOrders from "./hooks/useFetchDoneOrders"
import { Divider, Select } from "antd"
import useSwapManager from "./hooks/useSwapManager"
import Header from "./components/header"

function SwapUsersOrder() {
  const [doneOrders] = useFetchDoneOrders()
  const [users] = useFetchAllUsers()
  const swapManager = useSwapManager()

  return (
    <div className="flex flex-col items-center">
      <div className="mt-[50px]" />
      <Header />
      <div className="mt-[20px]" />
      <Divider />
      <Select
        className="w-96"
        placeholder="Choose done order"
        onSelect={(orderId) => swapManager.handleSwapInfo({ orderId })}
        value={swapManager.swapInfo?.orderId}
      >
        {doneOrders.map((doneOrder) => (
          <Select.Option value={doneOrder.id}>
            {doneOrder.id} - {doneOrder.category?.category}/
            {doneOrder.vertical?.vertical}
          </Select.Option>
        ))}
      </Select>
      <div className="mt-[50px]" />
      <Select
        className="w-96"
        placeholder="Choose user"
        onSelect={(userId) => swapManager.handleSwapInfo({ userId })}
        value={swapManager.swapInfo?.userId}
      >
        {users.map((user) => (
          <Select.Option value={user.userId}>
            {user.fullName} / {user.email}
          </Select.Option>
        ))}
      </Select>
      <div className="mt-[50px]" />
      {<div className="flex">
        <p className={`${ swapManager.showSuccessMessage ? "opacity-100" : "opacity-0"} transition-opacity ease-in-out text-teal-700 flex items-center flex-col`}>
          Swapping procedure performed <div className="font-bold">successfully</div>
        </p>
      </div>}
        <div className="mt-[50px]" />
      <div className="flex w-[180px] flex-row justify-between">
        <Button variant="secondary" onClick={swapManager.handleClearSwapInfo}>
          Clear
        </Button>
        <Button
          onClick={swapManager.handleSubmitSwap}
          loading={swapManager.isLoading}
          disabled={swapManager.isLoading}
        >
          SWAP
        </Button>
      </div>
    </div>
  )
}

export default SwapUsersOrder
