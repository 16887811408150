import networkService from "services/network/network.service"
import { IGetCreatorsName } from "./media-api.interfaces"
import Env from "@utils/env"

async function getCreatorsName(params: IGetCreatorsName) {
  const body = JSON.stringify({
    verticalId: params.verticalId,
    categoryId: params.categoryId,
    videoPart:
      params.videoPart === "All"
        ? ["CTA", "Hook", "Story"]
        : [params.videoPart],
  })

  const creatorsNames: string[] = await networkService
    .fetch(Env.get("REACT_APP_BASE_URL") + "/media/creators-name", {
      method: "POST",
      body,
    })
    .catch((error) => {
      throw new Error(error)
    })

  const data = creatorsNames || null

  if (!data) throw new Error("No creators name received.")

  const responseUnique = new Set()

  data.map((creatorName: string) =>
    creatorName.length ? responseUnique.add(creatorName) : null
  )

  return Array.from(responseUnique) as string[]
}

export default getCreatorsName
