import networkService from "services/network/network.service"
import { IMusicData } from "./media-api.interfaces"
import Env from "@utils/env"

async function getMusic(): Promise<IMusicData[]> {
  const musicList: IMusicData[] = await networkService
    .fetch(Env.get("REACT_APP_BASE_URL") + "/media/music-list", {
      method: "GET",
    })
    .catch((error) => {
      throw new Error(error)
    })

  if (!musicList.length) throw new Error("No music data received.")

  return musicList
}

export default getMusic
