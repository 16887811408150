import { Input } from "antd"
import { TextAreaProps } from "antd/es/input"
import React from "react"
import InputLabel from "../input-label"

interface Props extends TextAreaProps {
  label?: string
  columnView?: boolean
}
const TextArea: React.FC<Props> = (props) => {
  const { label, columnView = true, rows = 3, ...rest } = props

  return (
    <InputLabel columnView={columnView} label={label}>
      <Input.TextArea
        rows={rows}
        className="rounded-[var(--b-radius)] "
        size="large"
        autoSize={{ minRows: 3, maxRows: rows }}
        {...rest}
      />
    </InputLabel>
  )
}
export default TextArea
