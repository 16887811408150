import { Loader } from "@components/customized"
import { Suspense } from "react"
import { AdminMenuBar } from "./components"
import { Outlet } from "react-router-dom"
import useLoadCategories from "@utils/hooks/useLoadCategories"
import { useLoadCreators } from "@utils/hooks/useLoadCreators"
import Notification from "@components/customized/notification/notification.component"

const AdminLayout = () => {
  const { isLoading } = useLoadCategories(true)
  useLoadCreators()
  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Notification />
      <div className="flex h-screen flex-col overflow-hidden">
        <AdminMenuBar />
        <main className="h-full max-h-full overflow-auto bg-[var(--color-light-gray)]">
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </>
  )
}
export default AdminLayout
