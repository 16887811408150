import appStore from "@stores/app/app.store"
import { useEffect, useState } from "react"
import commonService from "services/api/REST/common"

const useLoadCategories = (getFullList = false) => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    async function getCategories() {
      setIsLoading(true)
      const categories = await commonService.getCategories(getFullList)
      appStore.setCategories(categories)
      setIsLoading(false)
    }

    getCategories()
  }, [getFullList])
  return { isLoading }
}

export default useLoadCategories
