import { Box, VideoPlayer } from "@components/customized"
import videoStore from "@stores/video/video.store"
import { observer } from "mobx-react-lite"

interface Props {
  link: string
  thumbImg?: string
  onPreviewClick: () => void
  uniqueVideoId: any
  height?: number
}

const VideoSection: React.FC<Props> = (props) => {
  const { onPreviewClick, link, height = 280, thumbImg, uniqueVideoId } = props
  const isPlaying = videoStore.currentPlayingVideo === uniqueVideoId
  return (
    <Box
      className="rounded-var(--b-radius) relative flex
        overflow-hidden"
    >
      <VideoPlayer
        thumbImg={{ src: thumbImg, alt: "video thumbnail" }}
        playerProps={{
          url: link,
          onError: console.log,
          playing: isPlaying,
          onClickPreview: () => {
            onPreviewClick()
            videoStore.setCurrentPlayingVideo(uniqueVideoId)
          },
          height,
          controls: true,
          onPlay: () => {
            videoStore.setCurrentPlayingVideo(uniqueVideoId)
          },
        }}
      />
    </Box>
  )
}
export default observer(VideoSection)
