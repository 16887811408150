import React, { useEffect } from "react";
import { notification } from "antd";
import type { NotificationArgsProps } from "antd";
import INotification from "./interfaces";
import appStore from "@stores/app/app.store";
import { observer } from "mobx-react-lite";

type NotificationPlacement = NotificationArgsProps["placement"];

const Context = React.createContext<INotification>({ title: "", desc: "" });

const Notification = observer(() => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.info({
      message: appStore.notification.title,
      description: appStore.notification.desc,
      placement,
    });
  };

  useEffect(() => {
    if(appStore.notification.title && appStore.notification.desc) openNotification("topRight");
  }, [appStore.notification]);

  return (
    <Context.Provider value={appStore.notification}>
      {contextHolder}
    </Context.Provider>
  );
});

export default Notification;
