import { Select as AntSelect, SelectProps } from "antd"
import "./select.css"
import InputLabel from "../input-label"

interface Props extends SelectProps {
  columnView?: boolean
}
function Select(props: Props) {
  const { size = "large", columnView, title = "", ...restProps } = props

  return (
    <InputLabel columnView={columnView} label={title}>
      <AntSelect className="min-w-52" {...restProps} size={size} />
    </InputLabel>
  )
}

export default Select

export const Option = AntSelect.Option
