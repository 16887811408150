import { InputNumber as AntInputNumber, InputNumberProps } from "antd"
import { forwardRef } from "react"
import InputLabel from "../input-label"

interface Props extends InputNumberProps {
  label?: string
  columnView?: boolean
}

const InputNumber = forwardRef<InputNumberProps, Props>(
  ({ label, columnView = true, ...props }, ref) => {
    return (
      <InputLabel columnView={columnView} label={label}>
        <AntInputNumber className="w-full" size="large" {...props} />
      </InputLabel>
    )
  }
)

InputNumber.displayName = "InputNumber"

export default InputNumber
