import * as Firebase from "@firebase/auth"
import Env from "@utils/env"
import { initializeApp } from "firebase/app"

class FirebaseService {
  public app: any = undefined

  constructor() {
    this.app = initializeApp({
      apiKey: Env.get("REACT_APP_FIREBASE_API_KEY"),
      authDomain: Env.get("REACT_APP_FIREBASE_AUTH_DOMAIN"),
      projectId: Env.get("REACT_APP_FIREBASE_PROJECT_ID"),
      storageBucket: Env.get("REACT_APP_FIREBASE_STORAGE_BUCKET"),
      messagingSenderId: Env.get("REACT_APP_FIREBASE_MESSAGING_SENDER_ID"),
      appId: Env.get("REACT_APP_FIREBASE_APP_ID"),
    })
    
    this.GoogleAuthProvider.setCustomParameters({
      prompt: "select_account",
    })
  }

  public FacebookAuthProvider = Firebase.FacebookAuthProvider
  public GoogleAuthProvider = new Firebase.GoogleAuthProvider()
  public OAuthProvider = Firebase.OAuthProvider
  public getAuth = Firebase.getAuth
  public signInWithEmailAndPassword = Firebase.signInWithEmailAndPassword
  public signInWithCredential = Firebase.signInWithCredential
  public createUserWithEmailAndPassword =
    Firebase.createUserWithEmailAndPassword
  public sendPasswordResetEmail = Firebase.sendPasswordResetEmail
  public onAuthStateChanged = Firebase.onAuthStateChanged
}

export default new FirebaseService()
