import { Box } from "@components/customized"
import { Spin } from "antd"

function Loader() {
  return (
    <Box className="pointer-events-none absolute z-50 grid h-screen w-screen place-items-center bg-white/90">
      <Spin size="large" />
    </Box>
  )
}

export default Loader
