import { Input as InputAnt, InputProps } from "antd"
import { forwardRef, useMemo } from "react"
import InputLabel from "../input-label"

interface Props extends InputProps {
  type?: "password"
  label?: string
  columnView?: boolean
}

const Input = forwardRef<any, Props>(
  ({ type, label, columnView = true, size = "large", ...props }, ref) => {
    const Component = useMemo(() => {
      return type === "password" ? InputAnt.Password : InputAnt
    }, [type])

    return (
      <InputLabel columnView={columnView} label={label}>
        <Component ref={ref} size={size} {...props} />
      </InputLabel>
    )
  }
)

Input.displayName = "Input"

export default Input
