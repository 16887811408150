import networkService from "services/network/network.service"
import { MEDIA_PAGE_SIZE } from "./get-media.service"
import IGetPublicUrls, {
  IGetMediaRes,
  IGetPublicUrlsRes,
} from "./media-api.interfaces"
import Env from "@utils/env"
import { VideoPartTypeEnum } from "../videos/videos-api.interfaces"

async function getPrivateMedia({ page = 1, ...params }: IGetPublicUrls) {
  const take = MEDIA_PAGE_SIZE
  const body = JSON.stringify({
    ...params,
    videoPart:
      params.videoPart === "All"
        ? ["CTA", "Hook", "Story"]
        : [params.videoPart],
    pagination: {
      skip: (page - 1) * take || 0,
      take,
    },
  })

  const mediasResponse: IGetPublicUrlsRes = await networkService
    .fetch(Env.get("REACT_APP_BASE_URL") + "/media/private", {
      method: "POST",
      body,
    })
    .catch((error) => {
      throw new Error(error)
    })

  const data = mediasResponse?.records || null

  if (!data) throw new Error("No public urls received.")

  const response: IGetMediaRes = {
    data: [],
    pageInfo: {
      count: mediasResponse.count,
      done: mediasResponse.done,
      total: mediasResponse.total,
    },
  }

  for (let index = 0; index < data.length; index++) {
    const element = data[index]
    const videoPart = element.videoPart as VideoPartTypeEnum

    if (!videoPart || !element) return

    const transcript =
      element.speech2textResponse?.results[0]?.alternatives[0].transcript ?? ""

    response["data"].push({
      transcript,
      file: element.compressedLink,
      creatorName: element.creatorName,
      poster: element.posterLink,
      videoPart,
      id: element.id,
    })
  }

  return response
}

export default getPrivateMedia
