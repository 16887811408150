import { cn } from "@utils/styles/cn"
import Box, { IBox } from "../box/box.component"

const Skeleton: React.FC<IBox> = ({ className, ...props }) => {
  return (
    <Box
      className={cn(
        "animate-pulse bg-[var(--color-disabled)] opacity-10",
        className
      )}
      {...props}
    />
  )
}
export default Skeleton
