import { routes } from "@router/router"
import { Link } from "react-router-dom"
import { Icons } from "../icons"
interface Props {
  onClick?: () => void
  className?: string
  asImage?: boolean
}
const LetstokLogo: React.FC<Props> = ({ asImage, className, onClick }) => {
  if (asImage) {
    return <Icons.LetstokBlack className={className} />
  }

  return (
    <Link onClick={onClick} to={routes.home}>
      <Icons.LetstokBlack className={className} />
    </Link>
  )
}
export default LetstokLogo
