type EnvKey =
  | "REACT_APP_BASE_URL"
  | "REACT_APP_STORAGE_URL"
  | "REACT_APP_INTERCOM_APP_ID"
  | "REACT_APP_FIREBASE_API_KEY"
  | "REACT_APP_FIREBASE_AUTH_DOMAIN"
  | "REACT_APP_FIREBASE_PROJECT_ID"
  | "REACT_APP_FIREBASE_STORAGE_BUCKET"
  | "REACT_APP_FIREBASE_MESSAGING_SENDER_ID"
  | "REACT_APP_FIREBASE_APP_ID"
  | "REACT_APP_GCP_CLIENT_EMAIL"
  | "REACT_APP_GCP_CLIENT_ID"
  | "REACT_APP_GCP_CLIENT_SECRET"
  | "REACT_APP_GCP_QUOTA_PROJECT_ID"
  | "REACT_APP_GCP_REFRESH_TOKEN"
  | "REACT_APP_GCP_TYPE"
  | "REACT_APP_GCP_UNIVERSE_DOMAIN"
  | "REACT_APP_ENVIRONMENT"

class Env {
  static get(key: EnvKey) {
    return process.env[key] as string
  }

  /** @description to provide default values for environment variables*/
  static getValue(key: EnvKey, defaultValue: string) {
    return process.env[key] || defaultValue
  }
}

export default Env
