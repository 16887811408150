import { Box } from "@components/customized"
import { cn } from "@utils/styles/cn"
import {
  Pagination as AntPagination,
  PaginationProps as AntPaginationProps,
} from "antd"

function Pagination({ ...props }: AntPaginationProps) {
  return <AntPagination simple defaultCurrent={1} {...props} />
}

interface PaginationWrapperProps {
  children: React.ReactNode
  sticky?: boolean
}
const PaginationWrapper: React.FC<PaginationWrapperProps> = ({
  sticky = true,
  children,
}) => {
  return (
    <Box
      className={cn(
        ` bottom-[0px] flex
          w-full justify-center bg-[var(--color-light-gray)]
         py-2`,
        sticky ? "sticky" : "absolute"
      )}
    >
      {children}
    </Box>
  )
}

export const PaginationRoot = {
  Pagination,
  PaginationWrapper,
}
