import getBucketInfo from "./bucket-params.service";
import combinationSetting from "./combination-setting.service";
import generateVideo from "./generate-video.service";
import getOwnVideos from "./get-own-videos.service";

export default {
    getBucketInfo,
    generateVideo,
    getOwnVideos,
    combinationSetting
}