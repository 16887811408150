import { Text } from "@components/controllers"
import { Box, Icons } from "@components/customized"
import { routes } from "@router/router"
import { cn } from "@utils/styles/cn"
import { Link, useLocation } from "react-router-dom"

const Breadcrumbs = ({ separator = "/" }) => {
  const location = useLocation()

  const pathnames = location.pathname.split("/").filter((x) => x)
  if (pathnames.length === 0) {
    return null
  }

  return (
    <Box
      className="flex items-center gap-x-2
    text-[14px] text-[var(--color-secondary)]"
    >
      <Link
        className="hover:cursor-pointer hover:text-[var(--color-primary)]"
        to={routes.home}
      >
        <Icons.Home />
      </Link>

      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`
        const isLast = index === pathnames.length - 1

        return (
          <Box key={name} className="flex items-center gap-x-2">
            <Text>{separator}</Text>
            <Link
              className={cn(
                "hover:cursor-pointer hover:text-[var(--color-primary)]",
                isLast && "font-bold"
              )}
              to={routeTo}
            >
              {name}
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}

export default Breadcrumbs
