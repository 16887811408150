import { Text } from "@components/controllers"
import { cn } from "@utils/styles/cn"

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  headerTitle?: string
}

const Card: React.FC<Props> = (props) => {
  const { headerTitle, className, children, ...rest } = props
  return (
    <div
      className={cn(
        `flex flex-col rounded-[var(--b-radius)] border
        border-[var(--color-gray)] bg-[var(--color-white)] p-3
       `,
        className
      )}
      {...rest}
    >
      {headerTitle && (
        <Text weight="bold" type="header5" className="mb-2">
          {headerTitle}
        </Text>
      )}
      {children}
    </div>
  )
}
export default Card
