import networkService from "services/network/network.service"
import { IGetBucketInfoRes } from "./videos-api.interfaces"
import Env from "@utils/env"

async function getBucketInfo(): Promise<IGetBucketInfoRes> {
  const url = `${Env.get("REACT_APP_BASE_URL")}/videos/bucket-info`
  const bucketInfo: IGetBucketInfoRes = await networkService
    .fetch(url, { method: "GET" })
    .catch((error) => {
      throw new Error(error)
    })

  return bucketInfo
}

export default getBucketInfo
