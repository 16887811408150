function Header() {
  return (
    <div className="flex w-[470px] flex-row justify-between">
      <div className="text-4xl">SWAP user of</div>
      <div className="text-4xl font-bold">DONE</div>
      <div className="text-4xl">orders</div>
    </div>
  )
}

export default Header
