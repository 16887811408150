import React, { useEffect, useRef, useState } from "react";
import Box from "../box/box.component";
import { Icons } from "../icons";

function Audio({
  ...props
}: Omit<React.AudioHTMLAttributes<HTMLAudioElement>, "controls">) {
  const [play, setPlay] = useState<boolean>(false);
  const audioPlayerRef = useRef<HTMLAudioElement>(null);

  const onClickHandler = () => {
    if (!play) {
      audioPlayerRef.current?.play();
    } else audioPlayerRef.current?.pause();

    setPlay((prev) => !prev);
  };

  useEffect(() => {
    setPlay(false);
  }, [props.src]);

  return (
    <Box>
      <Box className="cursor-pointer" onClick={onClickHandler}>
        {play ? (
          <Icons.Pause className="text-2xl" />
        ) : (
          <Icons.Play className="text-2xl" />
        )}
      </Box>
      <audio {...props} ref={audioPlayerRef} controls={false} />
    </Box>
  );
}

export default Audio;
