import networkService from "services/network/network.service"
import { IGenerateVideoReq } from "./videos-api.interfaces"
import Env from "@utils/env"

async function generateVideo(videoParams: IGenerateVideoReq): Promise<void> {
  const {
    categoryId,
    verticalId,
    hookId,
    storyId,
    CTAId,
    // includeBackground,
    // includeImage,
    // musicUrl,
    subtitlesStyle,
  } = videoParams
  if (
    !hookId ||
    !storyId ||
    !CTAId ||
    // !subtitlesStyle ||
    !categoryId ||
    !verticalId
  )
    throw new Error("Not all params are set!")

  const url = `${Env.get("REACT_APP_BASE_URL")}/videos/generate`
  const body = JSON.stringify(videoParams)
  const generatedVideo = await networkService
    .fetch(url, {
      method: "POST",
      body,
    })
    .catch((error) => {
      throw new Error(error)
    })

  console.log(generatedVideo)
}

export default generateVideo
