import { Link, LinkProps } from "react-router-dom"
import Text from "../../controllers/text/text.component"
import "./linked-text.css"
import { Icons } from "../icons"

type Props = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    children?: React.ReactNode
  }
const LinkedText: React.FC<Props> = (props) => {
  const { children, ...rest } = props
  return (
    <Link target="_blank" {...rest} className="flex items-center">
      <Text
        type="text1"
        className="linked-text
        hover:cursor-pointer 
        hover:opacity-70"
      >
        {children}
      </Text>
      <Icons.LinkOutlined className="text-[25px] text-[var(--color-primary)]" />
    </Link>
  )
}
export default LinkedText
