export const controllerPrefixes = {
  admin: "/admin",
  users: "/users",
  orders: "/users/orders",
}

export enum UploadAssetEndpoints {
  uploadHook = "upload-hook",
  uploadCTA = "upload-cta",
  uploadStory = "upload-story",
  uploadFootage = "upload-footage",
  uploadComment = "upload-comment",
}
