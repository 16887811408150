import { cn } from "@utils/styles/cn"
import IText from "./interfaces"
import { Skeleton } from "@components/customized"

function Text({
  shadow,
  weight,
  italic,
  type = "text1",
  children,
  className,
  isLoading,
  ...props
}: IText) {
  return (
    <span
      {...props}
      className={cn(
        "text-[var(--color-secondary)]",
        shadow && "text-shadow",
        italic && "italic",
        type === "header1" && "text-5xl font-extrabold lg:text-6xl",
        type === "header2" && "text-2xl font-bold lg:text-4xl",
        type === "header3" && "text-xl font-extrabold lg:text-3xl",
        type === "header4" && "text-xl lg:text-2xl",
        type === "header5" && "text:lg lg:text-xl",
        type === "header6" && "text-md lg:text-lg",

        type === "text1" && "text-base",
        type === "text2" && "text-sm",
        type === "text3" && "text-[12px]",

        type === "remarkInfo" && "text-sm lg:text-base",
        type === "remarkAlert" &&
          "text-sm text-[var(--color-alert)] lg:text-base",
        type === "remarkSuccess" &&
          "text-sm text-[var(--color-success)] lg:text-base",
        type === "remarkBold" && "text-sm font-semibold lg:text-base",

        weight === "extrabold" && "font-extrabold",
        weight === "bold" && "font-bold",
        weight === "extralight" && "font-extralight",
        weight === "light" && "font-light",
        weight === "medium" && "font-medium",
        className
      )}
    >
      {isLoading ? (
        <Skeleton className="h-[16px] rounded-[var(--b-radius)]" />
      ) : (
        children
      )}
    </span>
  )
}

export default Text
