import Env from "@utils/env"
import networkService from "services/network/network.service"
import { SSOAuth } from "./users-api.interfaces"

async function ssoUser(token: string): Promise<SSOAuth> {
  if (!token) throw new Error("No token received")

  const url = `${Env.get("REACT_APP_BASE_URL")}/users/sso`
  const body = JSON.stringify({ token })

  return await networkService
    .fetch(url, {
      method: "POST",
      body,
    })
    .catch((error) => {
      throw new Error(error)
    })
}

export default ssoUser
