import { Icons, Drawer, LetstokLogo } from "@components/customized"
import { useState } from "react"
import Sidebar from "../sidebar"

const MobileSidebar = () => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)
  return (
    <>
      <Drawer
        title={<LetstokLogo onClick={onClose} />}
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        mask
        extra={<Icons.Close onClick={onClose} />}
        key="mobile-sidebar"
      >
        <Sidebar onLinkClick={onClose} />
      </Drawer>
      <div
        onClick={onOpen}
        className="
        hover:cursor-pointer   lg:hidden"
      >
        <Icons.MenuHamburger />
      </div>
    </>
  )
}
export default MobileSidebar
