import { useEffect } from "react"
import commonService from "services/api/REST/common"
import appStore from "@stores/app/app.store"

export const useLoadCreators = () => {
  useEffect(() => {
    (async () => {
      if(appStore.creators.length) return;

      const creators = await commonService.getCreators().catch((e) => {
        console.warn(e.message)
        throw appStore.setNotify({
          title: "Request failed",
          desc: "Failed to get creators",
        })
      })

      appStore.setCreators(creators)
    })()
  }, [])
}
