import { Text } from "@components/controllers"
import Box from "../box/box.component"

interface FieldProps {
  label: string
  value: any
  defaultValue?: any
  isLoading?: boolean
}

const Field: React.FC<FieldProps> = ({ label, value, isLoading = false, defaultValue=null }) => (
  <Box>
    <Text type="text2" className="flex flex-col">
      {label}
      <Text isLoading={isLoading} type="text2" weight="bold"  defaultValue={defaultValue}>
        {value ||defaultValue || '-'}
      </Text>
    </Text>
  </Box>
)
export default Field
