import appStore from "@stores/app/app.store"
import { useCallback, useMemo } from "react"

export const useCategoriesUtil = (selectedCategoryId?: number) => {
  const categories = useMemo(() => {
    return appStore.categories.map((categoryItem) => ({
      label: categoryItem.category,
      value: categoryItem.id,
    }))
  }, [])

  const categoryVerticals = useMemo(() => {
    if (!selectedCategoryId) {
      return []
    }
    const category = appStore.categories.find(
      (categoryItem) => categoryItem.id === selectedCategoryId
    )

    return category
      ? category.verticals.map((verticalItem) => ({
          label: verticalItem.vertical,
          value: verticalItem.id,
        }))
      : []
  }, [selectedCategoryId])

  const allVerticals = useMemo(() => {
    return appStore.categories
      .map((categoryItem) => categoryItem.verticals)
      .flat()
      .map((verticalItem) => ({
        label: verticalItem.vertical,
        value: verticalItem.id,
      }))
  }, [])
  const getCategoryById = useCallback((id?: number) => {
    if (!id) return
    const category = appStore.categories.find(
      (categoryItem) => categoryItem.id === id
    )
    return category ? category.category : undefined
  }, [])
  const getVerticalById = useCallback(
    (id?: number) => {
      if (!id) return
      const vertical = allVerticals.find(
        (verticalItem) => verticalItem.value === id
      )
      return vertical ? vertical.label : undefined
    },
    [allVerticals]
  )

  const getCategoriesTreeSelectOptions = useCallback(
    (params?: { isParentDisabled?: boolean }) => {
      return appStore.categories.map((categoryItem) => ({
        label: categoryItem.category,
        value: categoryItem.id,
        disabled: params?.isParentDisabled ? true : false,
        children: categoryItem.verticals.map((verticalItem) => ({
          label: verticalItem.vertical,
          value: verticalItem.id,
        })),
      }))
    },
    []
  )
  return {
    categories,
    categoryVerticals,
    allVerticals,
    getVerticalById,
    getCategoriesTreeSelectOptions,
    getCategoryById,
  }
}
