import { Text } from "@components/controllers"

const TriggerLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <span
      className="rounded-[5px] px-2
      hover:cursor-pointer hover:bg-[var(--color-light-gray)]"
    >
      <Text type="text2">{label}</Text>
    </span>
  )
}
export default TriggerLabel
