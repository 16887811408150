import appStore from "@stores/app/app.store";
import mediaApi from "services/api/REST/media/media.api";

export function useLoadMusicList() {
  const fetchMusicHandler = async () => {
    const musicList = await mediaApi.getMusic().catch((error) => {
      console.warn(error);
      return [];
    });

    appStore.setMusicList(musicList);
  };

  return {
    fetchMusicHandler,
  };
}


