import { UserPlan } from "./plan/plan.types"

export enum UserRoles {
  Admin = "admin",
  Guest = "guest",
  Regular = "regular",
  Editor = "editor",
}
export enum UserStatus {
  Active = "active",
  Inactive = "inactive",
}

export interface SSOAuth {
  user: IUser
  plan: UserPlan
}
export interface IUser {
    id: string
    userId: string
    fullName: string
    email: string
    type: UserRoles
    status: UserStatus
}
