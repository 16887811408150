import React from "react"
import { TypeAnimation } from "react-type-animation"
import ITypeAnimated from "./interfaces"

function TypeAnimated({
  className,
  text,
  wrapper = "span",
  speed = 50,
}: ITypeAnimated) {
  return (
    <TypeAnimation
      sequence={[text]}
      wrapper={wrapper}
      speed={speed}
      className={className}
      cursor={false}
    />
  )
}

export default TypeAnimated
