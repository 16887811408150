import { Drawer as AntDrawer, DrawerProps } from "antd"

interface Props extends DrawerProps {}
const Drawer: React.FC<Props> = (props) => {
  return (
    <AntDrawer
      styles={{
        body: {
          padding: 0,
        },
        header: {
          padding: "16px",
        },
      }}
      {...props}
    />
  )
}
export default Drawer
