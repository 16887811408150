import { useEffect, useState } from "react"
import { IUser } from "services/api/REST/users/users-api.interfaces"
import usersApi from "services/api/REST/users/users.api"

function useFetchAllUsers() {
  const [users, setUsers] = useState<IUser[]>([])

  const fetchAllUsers = async () => {
    try {
      const usersResponse = await usersApi.getAllUsers()
      console.log(usersResponse)
      if (!usersResponse || !usersResponse.data) return

      setUsers(usersResponse.data)
    } catch (error) {
      console.log("Function: fetchAllUsers failed: " + error)
    }
  }

  useEffect(() => {
    fetchAllUsers()
  }, [])

  return [users]
}

export default useFetchAllUsers
