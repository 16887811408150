import { Text } from "@components/controllers"
import { Dropdown, MenuProps } from "antd"
import TriggerLabel from "./trigger-label"
import { Link, useLocation } from "react-router-dom"
import { routes } from "@router/router"
import { Icons } from "@components/customized"
import { useMemo } from "react"
import userStore from "@stores/user/user.store"
import { UserRoles } from "services/api/REST/users/users-api.interfaces"
import { useLogout } from "@utils/hooks"

const GoToMenu = () => {
  const location = useLocation()
  const logout = useLogout()
  const items: MenuProps["items"] = useMemo(() => {
    const list = [
      {
        key: "1",
        disabled: location.pathname === routes.admin.explorer,
        label: (
          <Link to={routes.admin.explorer} className="flex gap-x-1">
            {location.pathname === routes.admin.explorer && (
              <Icons.CheckOutlined className="text-[11px]" />
            )}
            <Text type="text2">To assets explorer</Text>
          </Link>
        ),
      },
    ]
    if (userStore.user?.type === UserRoles.Admin) {
      list.push({
        key: "2",
        label: (
          <Link to={routes.home}>
            <Text type="text2">To home</Text>
          </Link>
        ),
        disabled: false,
      })
    }
    list.push({
      key: "3",
      label: (
        <Text onClick={logout} type="text2">
          Logout
        </Text>
      ),
      disabled: false,
    })
    return list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  return (
    <Dropdown menu={{ items }} trigger={["hover"]}>
      {/* Empty div to fix bug with antd (looks like pointer event none )  */}
      <div>
        <TriggerLabel label="Go" />
      </div>
    </Dropdown>
  )
}
export default GoToMenu
