import { cn } from "@utils/styles/cn"
import React from "react"

export interface IBox extends React.HTMLAttributes<HTMLDivElement> {}
function Box({ className, ...props }: IBox) {
  return (
    <div className={cn(className)} {...props}>
      {props.children}
    </div>
  )
}

export default Box
