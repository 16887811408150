import { Box, Tooltip } from "@components/customized"
import Text from "../text/text.component"
import { cn } from "@utils/styles/cn"
import { InfoCircleOutlined } from "@ant-design/icons"

export type InputLabelProps = {
  columnView?: boolean
  label?: string
  tooltip?: {
    title: string
    icon?: React.ReactNode
  }
}
const InputLabel: React.FC<InputLabelProps & { children?: React.ReactNode }> = (
  props
) => {
  const { children, columnView, label, tooltip } = props
  if (!label) return <>{children}</>

  return (
    <Box
      className={cn(
        "flex w-full",
        columnView ? "flex-col" : "items-center justify-between"
      )}
    >
      {tooltip ? (
        <Tooltip title={tooltip.title} showArrow={false} arrow={false}>
          <Text
            weight="medium"
            className="flex cursor-help items-center gap-x-2"
          >
            {label} <InfoCircleOutlined />
          </Text>
        </Tooltip>
      ) : (
        <Text weight="medium">{label}</Text>
      )}
      {children}
    </Box>
  )
}
export default InputLabel
