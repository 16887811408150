import appStore from "@stores/app/app.store"
import Env from "@utils/env"
import axios from "axios"
export class NetworkService {
  public _headers: { [key: string]: string } = {
    token: "",
  }

  public setToken(value: string) {
    this._headers.token = value
  }

  public get axios() {
    return axios.create({
      baseURL: Env.get("REACT_APP_BASE_URL"),
      headers: {
        token: this._headers.token,
      },
    })
  }

  public async fetch(input: RequestInfo = "", init?: RequestInit) {
    if (!input) throw new Error("No url received.")

    appStore.setIsLoading(true)
    const isFormData = init?.body instanceof FormData

    let headers: any = {
      ...this._headers,
      ...init?.headers,
    }

    if (!isFormData) {
      headers["Content-Type"] = "application/json"
    }

    const response = await fetch(input, {
      ...init,
      body: init?.body,
      headers: { ...headers } as HeadersInit,
    }).catch((error) => {
      console.log("fetch error:", JSON.stringify(error))
      appStore.setIsLoading(false)
      throw error
    })

    const statusCode = response?.status || 500
    if (statusCode.toString()[0] !== "2") {
      appStore.setIsLoading(false)
      throw new Error("Request failed with status code: " + statusCode)
    }

    const contentType: string | null = response?.headers?.get("content-type")
    const isJsonFormat = contentType
      ? contentType.includes("application/json")
      : false

    const responseData = isJsonFormat ? await response.json() : true

    appStore.setIsLoading(false)

    return responseData
  }
}
export default new NetworkService()
