import { cn } from "@utils/styles/cn"
import { useState } from "react"
import { IVideoPlayer } from "."

export const ThumbImg = (props: IVideoPlayer["thumbImg"]) => {
  const [error, setError] = useState(false)

  return (
    <div
      className={cn(
        "rounded-var(--b-radius) relative  h-full w-full",
        props?.className
      )}
    >
      {error ? (
        <div className="primary-gradient h-full w-full rounded-[var(--b-radius)] opacity-40" />
      ) : (
        <img
          src={props?.src}
          alt={props?.alt}
          className={cn(
            `rounded-var(--b-radius) h-full w-full 
           object-contain opacity-80`
          )}
          onError={(err) => {
            setError(true)
          }}
        />
      )}
    </div>
  )
}
