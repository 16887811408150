import { Icons } from "@components/customized"
import IButton from "./interfaces"
import { cn } from "@utils/styles/cn"
import { forwardRef } from "react"

const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      children,
      size,
      className,
      variant = "primary",
      disabled,
      loading,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        className={cn(
          `
      flex
      h-10
      cursor-pointer
      items-center justify-center
      gap-x-1
      rounded-[var(--b-radius)]
      border border-[var(--color-gray)] px-5
      transition-all
      duration-300
      hover:bg-black/5
      hover:opacity-80
      `,
          variant === "ghost" && `border-0`,
          variant === "link" && `border-0 underline underline-offset-4`,
          variant === "settings" && `bg-[var(--color-gray)]`,
          variant === "default" && `bg-[var(--color-white)]`,
          variant === "icon" && "aspect-square p-0 text-[23px]",
          variant === "outlined" &&
            "border-[var(--color-gray)] text-[var(--color-secondary)]",
          variant === "primary" &&
            "border-[var(--color-primary)] bg-[var(--color-primary)] text-[var(--color-white)] hover:bg-[var(--color-primary)]",
          variant === "secondary" &&
            "border-[var(--color-secondary)] bg-[var(--color-secondary)] text-[var(--color-white)] hover:bg-[var(--color-white)] hover:text-[var(--color-secondary)]",
          variant === "gradient" &&
            "primary-gradient text-[var(--color-white)] hover:bg-[var(--color-primary)]",
          className,
          loading && "pointer-events-none opacity-80",
          size === "small" && "h-7 px-4",
          size === "large" && "h-12 px-8",
          disabled && "cursor-not-allowed opacity-50"
        )}
        {...props}
      >
        {loading ? <Icons.Loading /> : children}
      </button>
    )
  }
)
export default Button
