import ReactPlayer, { Config } from "react-player"
import "./video-player.css"
import { BaseReactPlayerProps } from "react-player/base"
import { ThumbImg } from "./video-player.util"

interface PlayerProps extends BaseReactPlayerProps {}
export interface IVideoPlayer {
  playerProps: PlayerProps
  noDownload?: boolean
  thumbImg?: {
    src?: string
    alt?: string
    className?: string
  }
}
const VideoPlayer: React.FC<IVideoPlayer> = ({
  playerProps,
  noDownload = false,
  ...props
}) => {
  const config: Config = {}
  const { thumbImg } = props

  if (noDownload) {
    config["file"] = {
      attributes: {
        controlsList: "nodownload",
      },
    }
  }

  return (
    <ReactPlayer
      light={thumbImg ? <ThumbImg {...thumbImg} /> : false}
      onError={console.error}
      height={280}
      config={config}
      {...playerProps}
    />
  )
}
export default VideoPlayer
