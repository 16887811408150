import networkService from "services/network/network.service"
import {
  ICombinationSettingReq,
  ICombinationSettingRes,
} from "./videos-api.interfaces"
import { isEmpty } from "lodash"
import Env from "@utils/env"

async function combinationSetting(
  params: ICombinationSettingReq
): Promise<ICombinationSettingRes> {
  if (!params) throw new Error("Params not received.")

  const validParams = Object.values(params).every((val) => !isEmpty(val.toString()))

  if (!validParams) throw new Error("All params should not be empty.")

  const searchParams = new URLSearchParams()
  searchParams.append("categoryId", params.categoryId.toString())
  searchParams.append("verticalId", params.verticalId.toString())
  searchParams.append("hookUrl", params.hookUrl)
  searchParams.append("storyUrl", params.storyUrl)
  searchParams.append("ctaUrl", params.ctaUrl)

  const url = `${Env.get("REACT_APP_BASE_URL")}/videos/combination-setting?${searchParams.toString()}`

  return await networkService
    .fetch(url, {
      method: "GET",
    })
    .catch((error) => {
      console.log(error)
      throw new Error("The request to the server failed.")
    })
}

export default combinationSetting
