import userStore from "@stores/user/user.store"
import { Navigate, useLocation } from "react-router-dom"
import { UserRoles } from "services/api/REST/users/users-api.interfaces"
import { routes } from "./router"

interface Props {
  Component: React.ComponentType
  roles: UserRoles[]
}
const getDefaultPathByRole = (params: {
  userRole?: UserRoles
  requestedPath: string
}) => {
  const { userRole, requestedPath } = params

  switch (userRole) {
    case UserRoles.Admin:
      return requestedPath
    case UserRoles.Editor:
      return routes.admin.explorer
    case UserRoles.Regular:
      return routes.home
    default:
      return routes.login
  }
}
const Protected: React.FC<Props> = (props) => {
  const { Component, roles } = props
  const location = useLocation()
  const userRole = userStore.user?.type
  const REDIRECT_PATH = getDefaultPathByRole({
    userRole: userRole,
    requestedPath: location.pathname,
  })
  if (userRole === UserRoles.Admin) return <Component />

  /** @description
   * Redirects the user to the login page
   * if they are not logged in or do not have the role
   */
  const isUnauthenticated = !userStore.loggedIn || !userStore.user || !userRole
  if (isUnauthenticated) {
    return <Navigate state={location.pathname} replace to={routes.login} />
  }

  /** @description
   * Redirects the user to the home page
   * if they are logged in but do not have the required role
   */
  const isUnauthorized = !roles.includes(userRole)
  if (isUnauthorized) {
    return <Navigate state={REDIRECT_PATH} replace to={routes.login} />
  }

  return <Component />
}
export default Protected
