import FileMenu from "./file-menu"
import GoToMenu from "./go-to-menu"
const AdminMenuBar = () => {
  return (
    <div
      className="flex  h-[32px] w-full
    items-center gap-x-2 
    border-b border-[var(--border-color)] bg-[var(--color-white)] p-1"
    >
      <FileMenu />
      <GoToMenu />
    </div>
  )
}
export default AdminMenuBar
