import networkService from "services/network/network.service"
import { controllerPrefixes } from "../rest.util"
import {
  AddOrderDto,
  ApproveDraftOrderDto,
  GetOrderByIdResponse,
  GetOrdersParams,
  GetOrdersResponse,
  OrderStatus,
  SwapUserOrderDto,
} from "./orders-api.types"
import appStore from "@stores/app/app.store"

class OrdersService {
  private ordersUrlPrefix = controllerPrefixes.orders

  public async getOrders(
    params: GetOrdersParams,
    status: "done" | "in-progress" | "draft"
  ): Promise<GetOrdersResponse> {
    return await networkService.axios
      .get(`${this.ordersUrlPrefix}/${status}`, {
        params,
      })
      .then((res) => res.data)
      .catch((e) => {
        console.warn(e.message)
        appStore.setNotify({
          title: "Fetching error",
          desc: "Error while getting orders",
        })

        return {
          data: [],
          total: 0,
          totalPages: 0,
        }
      })
  }

  public async getUserOrders(
    params: GetOrdersParams,
    status: OrderStatus[]
  ): Promise<GetOrdersResponse> {
    return await networkService.axios
      .get(`${this.ordersUrlPrefix}/own-orders`, {
        params: { ...params, status },
      })
      .then((res) => res.data)
      .catch((e) => {
        console.warn(e.message)
        appStore.setNotify({
          title: "Fetching error",
          desc: "Error while getting orders",
        })

        return {
          data: [],
          total: 0,
          totalPages: 0,
        }
      })
  }

  public async getOrderById(
    id: number,
    status: OrderStatus
  ): Promise<GetOrderByIdResponse> {
    appStore.setIsLoading(true)
    const url = `${this.ordersUrlPrefix}/${status}/${id}`
    return await networkService.axios
      .get(url)
      .then((res) => {
        appStore.setIsLoading(false)
        return res.data
      })
      .catch((e) => {
        console.warn(e.message)
        appStore.setIsLoading(false)
        appStore.setNotify({
          title: "Fetching error",
          desc: "Error while getting specific order",
        })

        return {
          data: [],
          total: 0,
          totalPages: 0,
        }
      })
  }

  public async addOrder(payload: AddOrderDto): Promise<any> {
    const {
      categoryId,
      isPremium,
      isPrivate,
      verticalId,
      withAi,
      customVertical,
      customCategory,
      comments,
      creators,
      creatorsComments,
      language,
      platform,
      videoLinks,
      videoType,
      videos,
      variations,
    } = payload
    const url = this.ordersUrlPrefix
    const formData = new FormData()

    if (comments) {
      formData.append("comments", comments)
    }
    if (creatorsComments) {
      formData.append("creatorsComments", creatorsComments)
    }
    if (categoryId) {
      formData.append("categoryId", categoryId?.toString())
    }
    if (language) {
      formData.append("language", language)
    }
    if (creators && creators.length && videoType?.toLowerCase() === "ugc") {
      const creatorsWithCount = creators.map((creator) => ({
        id: creator.id,
        count: creator.count,
      }))
      formData.append("creators", JSON.stringify(creatorsWithCount))
    }
    if (videoLinks && videoLinks.length) {
      formData.append("videoLinks", JSON.stringify(videoLinks))
    }
    if (platform && platform.length) {
      formData.append("platforms", JSON.stringify(platform))
    }
    if (videoType) {
      formData.append("videoType", videoType)
    }
    if (verticalId) {
      formData.append("verticalId", verticalId?.toString())
    }
    if (customVertical) {
      formData.append("customVertical", customVertical)
    }
    if (customCategory) {
      formData.append("customCategory", customCategory)
    }

    if (videos && videos.length) {
      videos.forEach((file, index) => {
        formData.append(`videos`, file)
      })
    }

    formData.append("private", isPrivate ? "true" : "false")
    formData.append("withAi", withAi ? "true" : "false")
    formData.append("premiumEditing", isPremium ? "true" : "false")
    formData.append("variations", variations?.toString())

    return await networkService.axios
      .post(url, formData)
      .then((res) => res.data)
  }

  public async approveDraftOrder({
    orderId,
    categoryId,
    verticalId,
    customCategory,
    customVertical,
  }: ApproveDraftOrderDto) {
    const url = `${this.ordersUrlPrefix}/approve-draft`
    if (!orderId || !categoryId || !verticalId)
      throw new Error("Invalid required fields")

    return await networkService.axios
      .patch(url, {
        orderId,
        categoryId,
        verticalId,
        customCategory,
        customVertical,
      })
      .then(() => true)
      .catch((e) => {
        console.warn(e)

        appStore.setNotify({
          title: "Approve order error",
          desc:
            e?.response?.data?.message ||
            "Error while trying to approve the order",
        })
        return false
      })
  }

  public async swapUserOrder({ userId, orderId }: SwapUserOrderDto) {
    const url = `${this.ordersUrlPrefix}/swap-user-order`
    if (!userId.length || !orderId) throw new Error("Invalid required fields")
      console.log("called swapUserOrder");
    return await networkService.axios
      .patch(url, {
        userId,
        orderId,
      })
      .then(() => true)
      .catch((e) => {
        console.warn(e)

        appStore.setNotify({
          title: "Swap user order error",
          desc:
            e?.response?.data?.message ||
            "Error while trying to approve the order",
        })
        return false
      })
  }
}

const ordersService = new OrdersService()
export default ordersService
