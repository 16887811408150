import getCreatorsName from "./get-creators-name.service";
import getMedia from "./get-media.service";
import getMusic from "./get-music.service";
import getPrivateMedia from "./get-private-media.service";
import getStickyTitleText from "./get-sticky-title-text.service";

export default {
  getMedia,
  getPrivateMedia,
  getCreatorsName,
  getMusic,
  getStickyTitleText
}