import { makeAutoObservable } from "mobx"
import { IBucketInfo } from "./video.interfaces"

class VideoStore {
  public currentPlayingVideo: string | number = ""
  public bucketInfo: IBucketInfo = {}

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentPlayingVideo(currentPlayingVideo: string | number) {
    this.currentPlayingVideo = currentPlayingVideo
  }

  setBucketInfo(bucketInfo: IBucketInfo) {
    this.bucketInfo = bucketInfo
  }
}

const videoStore = new VideoStore()
export default videoStore
