import React from "react"
import { TreeSelect as AntTreeSelect, TreeSelectProps } from "antd"
import InputLabel from "../input-label"

interface Props extends TreeSelectProps {
  columnView?: boolean
  title?: string
}
const TreeSelect: React.FC<Props> = (props) => {
  const { columnView = true, title, ...rest } = props
  return (
    <InputLabel columnView={columnView} label={title}>
      <AntTreeSelect
        showSearch
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Please select"
        allowClear
        treeLine
        size="large"
        treeDefaultExpandAll
        {...rest}
      />
    </InputLabel>
  )
}

export default TreeSelect
