import { Text } from "@components/controllers"
import { Dropdown, MenuProps } from "antd"
import TriggerLabel from "./trigger-label"
import { Link, useLocation } from "react-router-dom"
import { routes } from "@router/router"
import { Icons } from "@components/customized"
import { useMemo } from "react"
const FileMenu = () => {
  const location = useLocation()
  const items = useMemo(() => {
    const items = [
      {
        path: routes.admin.uploadAsset.hook,
        label: "Upload Hook",
      },
      {
        path: routes.admin.uploadAsset.cta,
        label: "Upload CTA",
      },
      {
        path: routes.admin.uploadAsset.footage,
        label: "Upload Footage",
      },
      {
        path: routes.admin.uploadAsset.story,
        label: "Upload Story",
      },
      {
        path: routes.admin.uploadAsset.comment,
        label: "Upload Comment",
      },
      {
        path: routes.admin.uploadAsset.newOrder,
        label: "Upload New Order",
      },
    ]
    return [
      {
        key: "1",
        label: <Text type="text2">Upload</Text>,
        children: items.map((item, index) => {
          const isActive = location.pathname === item.path
          return {
            key: `1-${index}`,
            disabled: isActive,
            label: (
              <Link to={item.path} className="flex gap-x-1">
                {isActive && <Icons.CheckOutlined className="text-[11px]" />}
                <Text
                  type="text2"
                  className={isActive ? "text-[var(--color-disabled)]" : ""}
                >
                  {item.label}
                </Text>
              </Link>
            ),
          }
        }),
      },
    ] as MenuProps["items"]
  }, [location.pathname])
  return (
    <Dropdown menu={{ items }} trigger={["hover"]}>
      <div>
        <TriggerLabel label="File" />
      </div>
    </Dropdown>
  )
}
export default FileMenu
