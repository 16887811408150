import networkService from "services/network/network.service"
import { controllerPrefixes } from "../rest.util"
import appStore from "@stores/app/app.store"

async function getAllUsers() {
  const usersUrlPrefix = controllerPrefixes.users

  const url = `${usersUrlPrefix}/all-users`
  return await networkService.axios.get(url).catch((e)=>{
    appStore.setNotify({title:"Error", desc:"Failed to retrieve users."})
    console.warn(e)
    throw new Error("Could not retrieve users")
  })
}

export default getAllUsers
