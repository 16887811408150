import networkService from "services/network/network.service"
import { IVideo } from "./videos-api.interfaces"
import Env from "@utils/env"

async function getOwnVideos(): Promise<IVideo[]> {
  const url = `${Env.get("REACT_APP_BASE_URL")}/videos/own`

  const ownVideos: IVideo[] = await networkService
    .fetch(url, {
      method: "GET",
    })
    .catch((error) => {
      console.log(error)
      throw new Error("The request to the server failed.")
    })

  return ownVideos
}

export default getOwnVideos
